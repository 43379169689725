import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import Layout from "../components/layout";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import MapGL, { Marker, NavigationControl } from "@urbica/react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useGeolocated } from "react-geolocated";
import { BiCurrentLocation } from "react-icons/bi";
import slugify from "slugify";
import InputField from "../components/inputField";
import ShortlistWidget from "../components/shortlist-widget";
import Sticky from "react-stickynode";
import ListingsPropertyCardNew from "../components/Listings-property-card-new";
import CartContext from "../context/CartContext";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { MdFormatListBulleted, MdOutlineClear } from "react-icons/md";
import FilterModal from "../components/filter-modal";
import ImageSlider from "../components/image-slider";
import MapCardModal from "../components/map-card-modal";
import PropertiesFilters from "../components/properties-filters";
import StarsIcon from "../images/5stars.svg";
import Layout02 from "../components/Layout02";
import ProcessStep from "../components/ProcessSteps";
import TrustedBy from "../components/TrustedBy";
import FavouriteCard from "../components/FavouriteCard";
import ContactForm from "../components/contactForm";
import ContactFormMultiStep from "../components/contact-form-multi-step";

const FavouritesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const {
		cart,
		settingVal,
		viewport,
		onLocationChange,
		numberOfPeople,
		filteredOffices,
		getDistanceFromLatLonInKm,
		distancePoint,
		OnRemoveFromCart,
		OnAddToCart,
		onLocationChangeScroll,
		onLocationChangePan,
		shortOffices,
		sortedBy,
		idLocation,
		capacityMinMax,
		showFilterMobile,
		selectedFeatures,
		selectedOfficeType,
		distanceAmount,
		budgetMinMax,
	} = useContext(CartContext);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const [vidShow, setVidShow] = useState(false);

	const handleVidShow = (e) => {
		e.preventDefault();
		setVidShow(true);
	};
	const handleVidClose = () => setVidShow(false);

	return (
		<>
			<GatsbySeo title="Your Office People Favourites" nofollow noindex />

			<Layout>
				<Modal
					style={{ zIndex: 9999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
					size="lg"
					show={vidShow}
					centered
					onHide={handleVidClose}
					className=" w-100 p-4  "
				>
					<div className="position-relative">
						<Modal.Body
							style={{
								overflow: "hidden",

								borderRadius: "12px",
							}}
							className="p-0 w-100  "
							closeButton={false}
						>
							<>
								<div
									style={{
										borderBottomLeftRadius: "12px",
										borderBottomRightRadius: "12px",
									}}
									className="p-4 mb-3 bg-white w-100"
								>
									<Row>
										<Col>
											<h4 className="pb-0 text-center">Book a viewing</h4>
											<p>
												Fill in your contact details below and we'll schedule
												your viewing in the first available slot.
											</p>
										</Col>
									</Row>
									<div className="d-none d-lg-block">
										<ContactForm />
									</div>
									<div className=" d-lg-none">
										<ContactFormMultiStep />
									</div>
									<Button
										onClick={handleVidClose}
										variant="outline-secondary"
										className="px-3 py-2 w-100 mt-2  text-black montserrat-bold"
									>
										Close
									</Button>
								</div>
							</>
						</Modal.Body>
					</div>
				</Modal>
				<section className="py-3">
					<Container>
						<Row className="align-items-center">
							<Col lg={8}>
								<p className="my-0 py-0">
									<span className="text-green houschka me-3 fs-5">
										Book multiple viewings:
									</span>
									You can select multiple offices and submit for booking.
								</p>
							</Col>
							<Col className="text-lg-end" lg={4}>
								{" "}
								<Button
									onClick={handleVidShow}
									className={`mt-2 w-100 w-md-auto fw-bold `}
									variant="transparent"
								>
									Book my viewings
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section style={{ background: "#F0F1F3" }} className="py-5 pb-7">
					<Container>
						{cart?.length > 0 && (
							<Row className="g-4">
								{" "}
								{cart?.map((property, i) => {
									return (
										<Col xs={12} md={6}>
											{" "}
											<FavouriteCard property={property} index={i} />
										</Col>
									);
								})}
							</Row>
						)}
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default FavouritesPage;
